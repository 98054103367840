export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/build/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:displayId',
    /* internal name: '/[displayId]' */
    /* no component */
    children: [
      {
        path: 'embed',
        name: '/[displayId]/embed',
        component: () => import('/build/src/pages/[displayId]/embed.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/setup',
    name: '/setup',
    component: () => import('/build/src/pages/setup.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

