type ScrinzEventType<Events extends { type: string; data?: unknown }> = {
	[E in Events as E["type"]]: E["data"];
};

type ScrinzInitEvent = {
	type: "scrinz:init";
	data?: {
		displayId: string;
		apiKey: string;
	};
};

type ScrinzInstallationDataEvent = {
	type: "scrinz:installation-data";
	data: {
		displayId: string;
		apiKey: string;
	};
};

type ScrinzEventsMap = ScrinzEventType<ScrinzInitEvent | ScrinzInstallationDataEvent>;
type ScrinzEventTypes = keyof ScrinzEventsMap;

type ScrinzEvent = ScrinzInitEvent | ScrinzInstallationDataEvent;

type ScrinzData = Record<string, any>;
type ScrinzListenerFn = (data: ScrinzData) => void;
type ScrinzListeners = Record<ScrinzEventTypes, ScrinzListenerFn[]>;

function isScrinzEvent(event: MessageEvent<ScrinzEvent>): event is MessageEvent<ScrinzEvent> {
	return event &&
		typeof event === "object" &&
		event.data &&
		typeof event.data === "object" &&
		"type" in event.data &&
		typeof event.data.type === "string" &&
		event.data.type.match(/^scrinz:/)
		? true
		: false;
	// TODO: Just assuming it's a scrinz event if it matches for now - some security checks migh be needed later
}

class ScrinzDisplayInternalApi {
	private _listeners: ScrinzListeners = {} as ScrinzListeners;
	private _source: null | MessageEventSource = null;

	constructor() {
		window.addEventListener("message", (event) => {
			if (!isScrinzEvent(event)) return;

			if (event.data.type === "scrinz:init" && !this._source) {
				this._source = event.source;
			}

			this._recieveMessage(event.data);
		});
	}

	private _recieveMessage(event: ScrinzEvent) {
		const listeners = this._listeners[event.type];
		if (!listeners) return;
		for (const listener of listeners) listener(event);
	}

	postMessage(type: ScrinzEventTypes, data?: ScrinzData) {
		this._source?.postMessage({ type, data });
	}

	addEventListener(type: ScrinzEventTypes, listener: ScrinzListenerFn) {
		if (!this._listeners[type]) this._listeners[type] = [];
		this._listeners[type].push(listener);
	}

	removeEventListener(type: ScrinzEventTypes, listener: ScrinzListenerFn) {
		const listeners = this._listeners[type];
		if (!listeners) return;
		const index = listeners.indexOf(listener);
		if (index > -1) listeners.splice(index, 1);
	}
}

class ScrinzDisplayAppApi {
	private _installation: null | ScrinzData = null;

	constructor(private _scrinz: ScrinzDisplayInternalApi) {
		this._init();
	}

	private _init() {
		const listener = (data: ScrinzData) => {
			if (!this._installation) this._installation = data;
			this._scrinz.removeEventListener("scrinz:init", listener);
		};

		this._scrinz.addEventListener("scrinz:init", listener);
	}

	getInstallationData() {
		return this._installation?.data ?? null;
	}

	setInstallationData(data: ScrinzInstallationDataEvent["data"]) {
		this._scrinz.postMessage("scrinz:installation-data", data);
	}

	onInstallationData(listener: ScrinzListenerFn) {
		this._scrinz.addEventListener("scrinz:installation-data", listener);
	}
}

if (!window.Scrinz) window.Scrinz = {} as ScrinzApi;
const scrinz = (window.Scrinz._internal = new ScrinzDisplayInternalApi());
window.Scrinz.app = new ScrinzDisplayAppApi(scrinz);

interface ScrinzApi {
	// postMessage(message: string): void;
	_internal: ScrinzDisplayInternalApi;
	app: ScrinzDisplayAppApi;
}

interface Window {
	Scrinz: ScrinzApi;
}
