import { createPinia } from "pinia";
import PiniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { routes } from "vue-router/auto-routes";
import { MotionPlugin } from "@vueuse/motion";

import App from "./App.vue";
import { createSentry } from "./plugins/sentry";

export function init() {
	// ! App
	const app = createApp(App);
	const pinia = createPinia();

	// ! Sentry
	createSentry(app, { pinia });

	// ! Pinia
	pinia.use(PiniaPluginPersistedstate);
	app.use(pinia);

	// ! Router
	const router = createRouter({ history: createWebHistory(import.meta.env.BASE_URL), routes });
	app.use(router);

	// Motion
	app.use(MotionPlugin);

	// ! Mount
	app.mount("#app");

	return { app, pinia, router };
}
