import "./assets/main.css";

import { init } from "./init";
// const { router } = init();

window.addEventListener("message", (event) => {
	if (event.data.type === "scrinz:init") {
		const { router } = init();
		router.replace("/");
	}
});
